import React from "react";
import {
  Card,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Button from "@mui/material/Button";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import useSupabase from "../utils/useSupabase";
import { useEffect, useState } from "react";
import { ROUTINGDATA } from "./RoudingData";
import { useNavigate, useLocation } from "react-router-dom";
import nocourse from "../Images/nocourse.png";
import SortIcon from "@mui/icons-material/Sort";
import ShareIcon from "@mui/icons-material/Share";

function HomeCoursePage() {
  const supabase = useSupabase();
  const [course, setCourse] = useState([]);
  const navigate = useNavigate();
  const [filteredCourse, setFilteredCourse] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [subcategory, setSubcategory] = useState([]);
  const [subcategorySelect, setSubcategorySelect] = useState(null);
  const [All, setAll] = useState(null);
  const [hoveredCardId, setHoveredCardId] = useState(null);
  const isMobile = useMediaQuery("(max-width:600px)");
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const location = useLocation();

  console.log("location", location.state);

  const toggleOverlay = () => {
    setIsOverlayVisible(!isOverlayVisible);
  };

  useEffect(
    () => {
      fetchCourse();
      fetchSubcategory();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const fetchCourse = async () => {
    const { data } = await supabase
      .from("course")
      .select()
      .eq("isActive", true);
    if (location.state && location.state.id) {
      const filteredData = data.filter(
        (course) => course.id === location.state.id
      );
      setCourse([...filteredData]);
      setFilteredCourse([...data]);
    } else {
      setCourse([...data]);
      setFilteredCourse([...data]);
    }
  };

  const fetchSubcategory = async () => {
    const { data } = await supabase
      .from("subcategory")
      .select()
      .eq("isActive", true);
    setSubcategory(data);
  };

  useEffect(
    () => {
      if (subcategorySelect || All) {
        fetchFilterData();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [subcategorySelect, All]
  );

  const fetchFilterData = async () => {
    if (selectedIndex === 0 && All === "Popular Course") {
      setCourse(filteredCourse);
    } else {
      const { data } = await supabase
        .from("course")
        .select()
        .eq("courseSubCategory", subcategorySelect)
        .eq("isActive", true);
      setCourse([...data]);
    }
  };
  const handleShare = (courseId) => {
    const courseLink = `${window.location.origin}/#${ROUTINGDATA.DETAILSPAGE}?id=${courseId}`;
    if (navigator.share) {
      navigator
        .share({
          title: "Check out this course!",
          url: courseLink,
        })
        .then(() => console.log("Share successful"))
        .catch((error) => console.log("Error sharing", error));
    } else {
      alert("Your browser does not support the Web Share API.");
    }
  };

  return (
    <div style={{ backgroundColor: "#f2f6ff" }}>
      <div style={isMobile ? { padding: "10px" } : { padding: "0 50px" }}>
        {location.pathname.includes("/course") ? (
          <>
            <h1>Explore Courses</h1>
            <text style={{ fontSize: "20px", fontWeight: 550 }}>
              Enhance your job-relevant skills with our extensive range of
              courses.
            </text>
          </>
        ) : (
          <h2 style={{ textAlign: "center" }}>Explore Courses</h2>
        )}
        <Grid container sx={{ mt: 4 }}>
          <Grid md={3} xs={12} sx={{ pr: 3 }}>
            {/* <Autocomplete
              sx={{ mt: 2 }}
              size="small"
              options={options}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                    },
                  }}
                  {...params}
                  label="Sort By"
                  variant="outlined"
                />
              )}
            /> */}
            <div className="MobileViewFilter" onClick={toggleOverlay}>
              <h3>Filter</h3> <SortIcon sx={{ mt: 2.8, pl: 1 }} />
            </div>
            <div
              className="overlay-container"
              style={{ display: isOverlayVisible ? "block" : "none" }}
            >
              <div className="overlay-content1">
                <h3>Categories</h3>
                <Divider />
                <List>
                  {[{ subcategoryName: "Popular Course" }, ...subcategory].map(
                    (data, index) => (
                      <ListItem
                        key={index}
                        sx={{
                          cursor: "pointer",
                          borderRadius: "10px",
                          "&:hover": {
                            backgroundColor: "rgba(57, 181, 74, 0.8)",
                            color:
                              selectedIndex === index ? "white" : "inherit",
                          },
                          backgroundColor:
                            selectedIndex === index ? "#39b54a" : "inherit",
                          color: selectedIndex === index ? "white" : "inherit",
                          my: 2,
                          fontSize: "12px",
                        }}
                        onClick={() => {
                          setSubcategorySelect(data.subcategoryCode);
                          setAll(data.subcategoryName);
                          setSelectedIndex(index);
                          toggleOverlay();
                        }}
                      >
                        <ListItemText
                          primary={data.subcategoryName}
                          primaryTypographyProps={{
                            sx: {
                              fontWeight: 600,
                              fontSize: "14px ",
                            },
                          }}
                        >
                          {data.subcategoryName}
                        </ListItemText>
                      </ListItem>
                    )
                  )}
                </List>
              </div>
            </div>
            <div
              style={{ position: "sticky", top: "0" }}
              className="MobileViewCategories"
            >
              <Card sx={{ px: 2 }}>
                <h3>Categories</h3>
                <Divider />
                <List>
                  {[{ subcategoryName: "Popular Course" }, ...subcategory].map(
                    (data, index) => (
                      <ListItem
                        key={index}
                        sx={{
                          cursor: "pointer",
                          borderRadius: "10px",
                          "&:hover": {
                            backgroundColor: "rgba(57, 181, 74, 0.8)",
                            color:
                              selectedIndex === index ? "white" : "inherit",
                          },
                          backgroundColor:
                            selectedIndex === index ? "#39b54a" : "inherit",
                          color: selectedIndex === index ? "white" : "inherit",
                          my: 0.5,
                        }}
                        onClick={() => {
                          setSubcategorySelect(data.subcategoryCode);
                          setAll(data.subcategoryName);
                          setSelectedIndex(index);
                        }}
                      >
                        <ListItemText
                          primary={data.subcategoryName}
                          primaryTypographyProps={{
                            sx: { fontWeight: 600, fontSize: "14px " },
                          }}
                        />
                      </ListItem>
                    )
                  )}
                </List>
              </Card>
            </div>
          </Grid>
          <Grid
            container
            xs={12}
            md={9}
            className="custom-scroll"
            sx={
              location.pathname.includes("/course")
                ? {}
                : {
                    maxHeight: "200vh",
                    overflowY: "auto",
                    behavior: "smooth",
                    overflowX: isMobile ? "auto" : "hidden",
                    flexWrap: isMobile ? "nowrap" : "wrap",
                    msOverflowStyle: "none",
                    scrollbarWidth: "none",
                    display: "flex",
                  }
            }
          >
            {course.length > 0 ? (
              <Grid
                container
                sx={{
                  overflowX: isMobile ? "scroll" : "unset",
                  flexWrap: isMobile ? "nowrap" : "wrap",
                }}
              >
                {course.map((data) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    sx={{
                      p: 2,
                      minWidth: isMobile ? "80vw" : "unset", // Adjust card width on mobile
                      marginRight: isMobile ? "10px" : "unset", // Add margin to show part of next card
                    }}
                  >
                    <Card
                      className="CourseCard"
                      style={{
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                        "&:hover": {
                          boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.3)",
                        },
                        borderRadius: "15px",
                        width: "100%", // Make sure the card takes the full width of the grid item
                      }}
                    >
                      <div style={{ position: "relative" }}>
                        <img
                          src={data.imageUrl}
                          alt="courseImage"
                          style={{ height: "150px", width: "100%" }}
                        />
                        <ShareIcon
                          style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            cursor: "pointer",
                            color: "white",
                          }}
                          onClick={() => handleShare(data.id)}
                        />
                      </div>
                      <div
                        className="courseCard"
                        style={{
                          padding: isMobile ? "0.5rem" : "1rem",
                          color: "#505050",
                          fontWeight: 600,
                        }}
                      >
                        <p
                          style={{
                            color: "#303030",
                            fontSize: isMobile ? "16px" : "20px",
                            fontWeight: 600,
                            margin: 0,
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "270px",
                          }}
                        >
                          {data.courseName}
                        </p>
                        <div style={{ display: "flex", margin: "-7px 0px" }}>
                          <AccessTimeIcon sx={{ mt: 1.8, pr: 0.4 }} />
                          <p>{data.duration} hours</p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            marginTop: "-1.5rem",
                            marginBottom: "-1.4rem",
                          }}
                        >
                          <SignalCellularAltIcon sx={{ mt: 1.8, pr: 0.4 }} />
                          <p>{data.lable} Level</p>
                        </div>
                        <p>By {data.trainer}</p>
                        <div style={{ display: "flex", marginTop: "-1.3rem" }}>
                          <p>Skills</p>
                          <div style={{ position: "relative" }}>
                            <ErrorRoundedIcon
                              sx={{
                                mt: 1.8,
                                pl: 0.4,
                                color: "black",
                                cursor: "pointer",
                              }}
                              onMouseEnter={() => setHoveredCardId(data.id)}
                              onMouseLeave={() => setHoveredCardId(null)}
                            />
                            {hoveredCardId === data.id && (
                              <div className="popup">
                                <ul>
                                  {data.skills
                                    .split(",")
                                    .map((skill, index) => (
                                      <li key={index}>{skill.trim()}</li>
                                    ))}
                                </ul>
                              </div>
                            )}
                          </div>
                        </div>
                        <Divider sx={{ border: "1px solid gray" }} />
                        <br />
                        <div
                          style={{
                            display: isMobile ? "" : "flex",
                            justifyContent: "space-between",
                            marginTop: "-0.5rem",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <del style={{ marginTop: "0.1rem" }}>
                              ₹{data.realfees}
                            </del>
                            <p
                              style={{
                                margin: 0,
                                paddingLeft: "10px",
                                color: "black",
                                fontSize: "18px",
                              }}
                            >
                              ₹{data.fees}
                            </p>
                          </div>
                          <Button
                            variant="contained"
                            onClick={() => {
                              navigate(
                                `${ROUTINGDATA.DETAILSPAGE}?id=${data.id}`
                              );
                              window.scrollTo(0, 0);
                            }}
                            sx={{
                              display: isMobile ? "flex" : "",
                              justifyContent: isMobile ? "center" : "",
                              pt: "3px",
                              backgroundColor: "#39b54a",
                              textTransform: "capitalize",
                              fontWeight: 550,
                              color: "white",
                              borderColor: "#39b54a",
                              borderRadius: "25px",
                              ml: isMobile ? "auto" : "",
                              cursor: "pointer",
                              "&:hover": {
                                textTransform: "capitalize",
                                color: "white",
                                backgroundColor: "#39b54a",
                                borderColor: "#39b54a",
                                fontWeight: 550,
                                borderRadius: "25px",
                                cursor: "pointer",
                              },
                            }}
                          >
                            Know More
                          </Button>
                        </div>
                      </div>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                style={{
                  minHeight: "150px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontSize: "20px",
                    color: "#505050",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={nocourse}
                    alt="nocourse"
                    style={{ height: isMobile ? "250px" : "430px" }}
                  />
                  <Typography
                    sx={{
                      font: "Inter",
                      marginTop: { md: "-50px" },
                      paddingLeft: "30px",
                      fontSize: {
                        xs: "20px",
                        sm: "30px",
                        md: "35px",
                      },
                    }}
                  >
                    No Courses Available....
                  </Typography>
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
        <br />
        <br />
      </div>
    </div>
  );
}

export default HomeCoursePage;
