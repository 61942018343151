import React, { useEffect, useState } from "react";
import "./HomePage.css";
import useSupabase from "../utils/useSupabase";
import { Grid, Rating } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const WebsiteReview = () => {
  const supabase = useSupabase();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [reviews, setReviews] = useState([]);
  const [expandedStates, setExpandedStates] = useState([]);

  useEffect(() => {
    const fetchReviews = async () => {
      const { data, error } = await supabase
        .from("websiteReview")
        .select("*")
        .eq("isActive", true)
        .order("package", { ascending: false });
      if (error) {
        console.error("Error fetching reviews:", error);
      } else {
        setReviews(data);
        // Initialize expanded states for each review to false
        setExpandedStates(new Array(data.length).fill(false));
      }
    };

    fetchReviews();
  }, [supabase]);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? reviews.length - 3 : prevIndex - 3
    );
    // Reset all expanded states when navigating
    setExpandedStates(new Array(reviews.length).fill(false));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === reviews.length - 3 ? 0 : prevIndex + 3
    );
    // Reset all expanded states when navigating
    setExpandedStates(new Array(reviews.length).fill(false));
  };

  const handleToggle = (index) => {
    setExpandedStates((prevExpanded) =>
      prevExpanded.map((exp, i) => (i === index ? !exp : exp))
    );
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
    // Reset all expanded states when switching reviews
    setExpandedStates(new Array(reviews.length).fill(false));
  };

  if (reviews.length === 0) {
    return <p>Loading testimonials...</p>;
  }

  const reviewsToShow = reviews.slice(currentIndex, currentIndex + 3);

  return (
    <div className="testimonial-container">
      <h2 className="title">Student's Success Story</h2>

      <div className="testimonial-content">
        <Grid container spacing={2}>
          {reviewsToShow.map((review, index) => {
            const {
              name,
              review: reviewText,
              firstLetter,
              link,
              imageUrl,
            } = review;

            return (
              <Grid item md={4} sm={6} key={index}>
                <div className="testimonial">
                  <div className="testimonial-header">
                    <div style={{ position: "relative" }}>
                      {imageUrl ? (
                        <img
                          className="initial-circle"
                          src={imageUrl}
                          alt={name}
                        />
                      ) : (
                        <div className="initial-circle">
                          {firstLetter || name.charAt(0)}
                        </div>
                      )}
                      {link && (
                        <div
                          style={{
                            position: "absolute",
                            bottom: 0,
                            right: 0,
                            marginRight: "10px",
                            marginBottom: "-5px",
                            cursor: "pointer",
                          }}
                        >
                          <img
                            width="30"
                            height="30"
                            src="https://img.icons8.com/fluency/30/linkedin.png"
                            alt="LinkedIn"
                            onClick={() => window.open(link, "_blank")}
                          />
                        </div>
                      )}
                    </div>
                    <div className="name-rating">
                      <h3 className="name">{name}</h3>
                      <text>
                        {review.company}-{review.package}Lacs
                      </text>
                      <div className="rating">
                        <Rating value={review.rating} readOnly />
                      </div>
                    </div>
                  </div>
                  <p
                    style={{
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: expandedStates[index] ? "unset" : 3,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      margin: 0,
                    }}
                  >
                    {reviewText}
                  </p>
                  <button
                    className="read-more-button"
                    onClick={() => handleToggle(index)}
                  >
                    {expandedStates[index] ? "Read Less" : "Read More"}
                  </button>
                </div>
              </Grid>
            );
          })}
        </Grid>
        <div className="navigation">
          <button
            className="nav-button"
            onClick={handlePrevClick}
            disabled={currentIndex === 0}
          >
            <ArrowBackIcon sx={{ fontSize: 40 }} />
          </button>
          <div className="dots-container">
            {Array.from({ length: Math.ceil(reviews.length / 3) }).map(
              (_, index) => (
                <span
                  key={index}
                  className={`dot ${
                    index === Math.floor(currentIndex / 3) ? "active" : ""
                  }`}
                  onClick={() => handleDotClick(index * 3)}
                >
                  &#8226;
                </span>
              )
            )}
          </div>
          <button
            className="nav-button"
            onClick={handleNextClick}
            disabled={currentIndex >= reviews.length - 3}
          >
            <ArrowForwardIcon sx={{ fontSize: 40 }} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default WebsiteReview;
